import type { NextPage } from "next";
import Head from "next/head";

const Home: NextPage = () => {
  return (
    <div>
      <Head>
        <title>404</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="noindex" />
      </Head>

      <p style={{textAlign:'center', padding: '2rem 0'}}>
        <h1>Whoops, not found!</h1>
      </p>
    </div>
  );
};

export default Home;
